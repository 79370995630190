module.exports = {
  siteTitle: 'Touch of Tranquility', // <title>
  manifestName: 'ToT',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/icon.jpg',
  //pathPrefix: ``, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Touch of Tranquility',
  subHeading: 'Massage for the Body, Mind, & Spirit',

  // social
  socialLinks: {
    // 'tripadvisor': {
    //   icon: 'fa-tripadvisor',
    //   name: 'Tripadvisor',
    //   background: '#00AF87',
    //   url: 'https://www.tripadvisor.com/',
    // },
    'yelp': {
      icon: 'fa-yelp',
      name: 'Yelp',
      background: '#C41200',
      url: 'https://www.yelp.com/biz/touch-of-tranquility-port-republic',
    },
    'facebook': {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      background: '#3B5998',
      url: 'https://www.facebook.com/Touch-of-Tranquility-564200073598359/',
    },
  },
  email: 'marita.ann7@gmail.com',
  phone: '000-00000',
  address: 'Port Republic',
};
